// @ts-nocheck
import { Complete } from '@ggs/components/ecomm/CheckoutSteps'
import { Grid } from '@mui/material'
import React from 'react'
/**
 * @typedef {Object} CheckoutCompleteProps
 * @property {string=} orderId
 * @property {Order=} orderCached
 */
/**
 * Checkout in progress steps layout.
 * @param {CheckoutInProgressLayoutProps} props
 * @return {JSX.Element}
 */
const CompleteCheckoutStepsLayout = ({
  orderCached,
  orderLoaded,
}) => {
  // TODO: restore create account banner for guest users
  // const { isAuth } = useIsAuth()
  // if (!order) {
  //   return null
  // }
  // TODO: want to get order from ID, get 401.. why isnt this working
  // const fetchLatestOrderState = async () => {
  //   if (token) {
  // clClientFactory.setToken(token)
  // const clClient = clClientFactory.getClient()
  // setCompleteOrder(async () => {
  // if (!(orderId || lastOrder?.id)) {
  //   return null
  // }
  // const orderId = order?.id
  // if (clClient) {
  // try {
  //   const orderApi = await retrieveOrder(clClient, orderId,
  //   setCompleteOrder)
  // } catch (e) {
  //   console.log(e)
  // }
  // const [completeOrder, setCompleteOrder] = useState(orderCached)
  // useEffect(() => {
  // if (token) {
  //   fetchLatestOrderState()
  //     // clClientFactory.setToken(token)
  //     // const clClient = clClientFactory.getClient()
  //     // retrieveOrder(clClient, orderId, setCompleteOrder)
  //     .then((r) => console.log(r))
  // }
  // console.log('awaited order', order)
  // }, [token, setCompleteOrder])

  return (
    <CompleteOrderLayout
      orderComplete={!!orderCached && orderLoaded}
      order={orderCached}
    />
  )
}

const CompleteOrderLayout = ({ order, orderComplete }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={12}>
        <Complete order={order} orderComplete={orderComplete}/>
      </Grid>
    </Grid>
  )
}


export default CompleteCheckoutStepsLayout
