import { useSupportingLinks } from '@ggs/components/ecomm'
import CompleteCheckoutStepsLayout from '@ggs/components/ecomm/Checkout/hooks/CompleteCheckoutStepsLayout'
import { useI18n } from '@ggs/gatsby/lib'
import { useSelector } from '@ggs/store'
import { getSearchParams } from '@ggs/utils'
import Box from '@mui/material/Box'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

export const CheckoutPlaced = ({ ecommEnabled = false }) => {
  const { t } = useI18n()
  const { productListing } = useSupportingLinks()
  const [orderLoaded, setOrderLoaded] = useState(false)
  const lastOrderId = useSelector((state) => state.checkout.lastOrderId)
  // read order ID from URLSearchParams
  const [orderId, setOrderId] = useState(() => {
    const qs = getSearchParams()
    return qs.get('oid') ?? lastOrderId
  })

  // check if order exists in local cache (redux / localStorage)
  const cachedPlacedOrders = useSelector((state) =>
    state.views.orders.placed
  )

  // check if order exists in local cache (redux / localStorage)
  const cachedDraftOrders = useSelector((state) =>
    state.views.orders.draft
  )


  const token = useSelector((state) =>
    state.commerce.token
  )

  // try params, try last active order, else null
  // const viewOrder = cachedPlacedOrders?.[orderId]
  //   ?? cachedPlacedOrders?.[lastOrderId]
  //   ?? null

  useEffect(() => {
    setOrderId(() => {
      const qs = getSearchParams()
      return qs.get('oid') ?? lastOrderId
    })
  }, [location, lastOrderId])

  useEffect(() => {
    // TODO: might need to wait for network to load order in the future
    // redirect if no order, or ecomm disabled.
    if (!ecommEnabled) {
      navigate(productListing)
    }
  }, [])

  //simulate network call and also give buffer for state transistions.
  useEffect(() => {
    setTimeout(() => {
      setOrderLoaded((currentState) => {
        if (cachedPlacedOrders?.[orderId]) {
          return true
        }
        return currentState
      })
    }, 3000)
  }, [lastOrderId, cachedPlacedOrders])

  // TODO: for network etc, if 15 seconds and still no order,
  // force re-check and/or redirect to products listing or?
  useEffect(() => {
    setTimeout(() => {
      setOrderLoaded(true)
    }, 16000)
  }, [])

  // check to see if there is an order or if we ran out of time with no order
  useEffect(() => {
    if (orderLoaded && !cachedPlacedOrders[orderId]) {
      navigate(productListing)
    }
  }, [orderLoaded, orderId])

  return (
    <>
      <Box
        className="checkout__content"
        sx={{
          '> .grid-container > div': {
            px: 0,
          },
          '& .divider': {
            px: 0,
          },
        }}
      >
        {ecommEnabled ? (
          <CompleteCheckoutStepsLayout
            orderId={orderId}
            orderCached={cachedPlacedOrders?.[orderId]}
            token={token}
            orderLoaded={orderLoaded}
          />
        ) : (
          t('ecomm:notice.ecommDisabled')
        )}
      </Box>
    </>
  )
}
